<template>
    <div class="p-3">
        <el-card shadow="never">
            <div class="row no-gutters">
                <div class="col">
                    <search-box v-model="key" style="width: 280px;" @search="loadFarmers(true)" />
                </div>
            </div>
            <el-table :data="farmers.data" class="mt-2">
                <el-table-column prop="registeredUser.name" label="姓名" sortable>
                    <template #default="scope">
                        <router-link :to="'/registered-user/detail?id='+scope.row.registeredUser.id" target="_blank">
                            {{scope.row.registeredUser.name}}
                        </router-link>
                    </template>
                </el-table-column>
                <el-table-column prop="registeredUser.idCard" label="身份证号" sortable />
                <el-table-column prop="contactName" label="联系人" sortable />
                <el-table-column prop="contactPhone" label="联系电话" sortable />
                <el-table-column label="操作" fixed="right" width="70">
                    <template #default="scope">
                        <el-button type="text" @click="showAuditFarmerDialog(scope.row)">审核</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,->,prev,pager,next" :total="farmers.totalRecords"
                :current-page="farmersPagination.currentPage" :page-size="farmersPagination.pageSize" class="mt-3"
                @current-change="farmersPaginationCurrentChange" />
        </el-card>

        <el-dialog :visible.sync="auditFarmerDialogVisible" title="审核" :close-on-click-modal="false" width="960px"
            top="0px">
            <iframe v-if="auditFarmerDialogVisible" :src="'/#/registered-user/detail?id='+farmer.registeredUser.id"
                frameborder="0" class="d-block border border-primary rounded w-100"
                style="height: calc(100vh - 400px);"></iframe>

            <div class="mt-3">
                <div class="row no-gutters">
                    <div class="col">
                        失信相关附件
                        <a href="http://zxgk.court.gov.cn/zhzxgk/" target="_blank">查询被执行人</a>
                    </div>
                    <div class="col-auto">
                        <el-radio v-model="farmer.hasRisk" :label="false">无风险</el-radio>
                        <el-radio v-model="farmer.hasRisk" :label="true">有风险</el-radio>
                    </div>
                </div>
                <div class="border rounded mt-2 p-1">
                    <picture-uploader v-model="farmer.discreditAttachments" />
                </div>
            </div>

            <el-form class="mt-2">
                <el-form-item label="审核备注">
                    <el-input v-model="auditRemark" placeholder="审核备注" />
                </el-form-item>
            </el-form>
            <template #footer>
                <el-button type="success" @click="auditFarmer(true)">审核通过</el-button>
                <el-button type="danger" @click="auditFarmer(false)">审核退回</el-button>
                <el-button @click="auditFarmerDialogVisible=false">取消</el-button>
            </template>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                key: null,
                farmers: {},
                farmersPagination: {
                    currentPage: 1,
                    pageSize: 10,
                },
                farmer: { registeredUser: {} },
                auditRemark: null,
                auditFarmerDialogVisible: false,
            };
        },
        methods: {
            async loadFarmers(resetPage = false) {
                if (resetPage) {
                    this.farmersPagination.currentPage = 1;
                }

                let response = await this.$axios.get('/api/Farmer/GetIndividualFarmers', {
                    params: {
                        companyId: this.$store.state.companyId,
                        status: 2,
                        key: this.key,
                        pageIndex: this.farmersPagination.currentPage - 1,
                        pageSize: this.farmersPagination.pageSize,
                    }
                });
                this.farmers = response.data;
            },
            farmersPaginationCurrentChange(page) {
                this.farmersPagination.currentPage = page;
                this.loadFarmers();
            },
            showAuditFarmerDialog(farmer) {
                this.farmer = JSON.parse(JSON.stringify(farmer));
                this.auditRemark = null;
                this.auditFarmerDialogVisible = true;
            },
            async auditFarmer(isPass) {
                if (!confirm(`确定要审核${isPass ? '通过' : '退回'}吗？`)) {
                    return;
                }

                await this.$axios.post('/api/Farmer/AuditFarmer', {
                    id: this.farmer.id,
                    hasRisk: this.farmer.hasRisk,
                    discreditAttachments: this.farmer.discreditAttachments,
                    isPass: isPass,
                    auditRemark: this.auditRemark,
                });
                this.auditFarmerDialogVisible = false;
                this.loadFarmers();
            },
        },
        created() {
            this.loadFarmers();
        },
    };
</script>